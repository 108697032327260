<template>
  <div id="chartsGraphOne" style="height: 340px">
    <div v-if="!isShowLoad" id="charts-graph"></div>
    <div v-else class="graph-loading"></div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';

  export default {
    name: 'ChartsGraphOne',
    props: {
      // 图表数据-服务端提供
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isShowLoad: true,
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler(val) {
          console.log('val', val);
          this.isShowLoad = false;
          this.$nextTick(() => {
            if (val.length > 0) {
              this.drawGraph();
            }
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.drawGraph();
        // this.myObserver.observe(document.getElementById('chartsGraphOne'));
      });
    },
    methods: {
      drawGraph() {
        const myChart = echarts.init(document.getElementById('chartsGraphOne'));
        const option = {
          tooltip: {
            trigger: 'axis',
            formatter: params => {
              const title = `<div style="font-weight: bold; margin-bottom: 10px">${params[0].axisValue}</div>`;
              let content = '';
              params.forEach(item => {
                const color = typeof item.color !== 'string' ? '#F7B500' : item.color;
                content += `
          <div style="margin-bottom: 6px">
            <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${color}"></span>
            <span>${item.seriesName}：${item.value}</span>
          </div>
        `;
              });
              return title + content;
            },
          },
          grid: {
            top: 50,
            right: 40,
            left: 60,
            bottom: 35,
          },
          // 图例
          legend: {
            top: 8,
            right: 9,
            itemGap: 5,
            itemWidth: 5,
            icon: 'circle',
          },

          xAxis: {
            type: 'category',
            data: this.data.map(item => {
              return item.dateTime;
            }),
            axisLine: {
              show: true, // 不隐藏轴线
            },

            axisTick: {
              show: false, // 隐藏分隔线
            },
            boundaryGap: false, // 坐标轴两边不留白
            // 标签文字样式修改
            axisLabel: {
              color: '#9DA5AF',
              margin: 12,
              formatter: (value, index) => {
                if (index === 0) return `{a|${value}}`;
                if (index === 6) return `{b|${value}}`;
                return value;
              },
              rich: {
                a: {
                  padding: [0, 0, 0, 20], // 左边距 20
                },
                b: {
                  padding: [0, 20, 0, 0], // 右边距 20
                },
              },
            },
          },
          yAxis: {
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: ['#EDEEF1'],
              },
            },
            axisLabel: {
              color: '#9DA5AF',
              margin: 20,
            },
          },
          series: [
            // 充电电量
            {
              name: '充电电量',
              type: 'line',
              smooth: true, // 平滑
              data: this.data.map(item => {
                return item.chargedPower;
              }),
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#F7B500', // 起始位置的颜色
                    },
                    {
                      offset: 1,
                      color: '#6DD400', // 结束位置的颜色
                    },
                  ]),

                  lineStyle: {
                    width: 1, // 线条宽度
                    shadowColor: '#6DD400', // 颜色
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    opacity: 1, // 透明度
                    shadowBlur: 8, // 阴影大小
                  },
                },
              },
              showSymbol: false, // 隐藏标记点
              symbol: 'circle', // 当鼠标悬浮在折线图上出现 tooltip 时，显示的标记类型
              symbolOffset: [0, '75%'], // 标记点的位置
              // 区域填充
              // areaStyle: {
              //   color: {
              //     type: 'linear', // 线性渐变
              //     x: 0,
              //     y: 0,
              //     x2: 0,
              //     y2: 1,
              //     colorStops: [
              //       {
              //         offset: 0,
              //         color: 'rgba(109,212,0,0.1)', // 0% 处的颜色
              //       },
              //       {
              //         offset: 1,
              //         color: 'rgba(247,171,0,0.01)', // 100% 处的颜色
              //       },
              //     ],
              //   },
              // },
            },
            // 充电金额
            {
              name: '充电金额',
              type: 'line',
              smooth: true, // 平滑
              data: this.data.map(item => {
                return item.totalAmount;
              }),
              itemStyle: {
                color: 'rgba(87, 83, 255,0.8)', // 改变颜色
                lineStyle: {
                  width: 1, // 线条宽度
                },
              },
              showSymbol: false, // 隐藏标记点
              symbol: 'circle', // 当鼠标悬浮在折线图上出现 tooltip 时，显示的标记类型
              symbolOffset: [0, '75%'], // 标记点的位置
              // 区域填充
              areaStyle: {
                color: {
                  type: 'linear', // 线性渐变
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(168, 229, 255, 0.1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 206, 229, 0.3)', // 100% 处的颜色
                    },
                  ],
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        this.myChart = myChart;
      },
    },
    activated() {
      this.drawGraph();
    },
    deactivated() {
      if (this.myObserver && document.getElementById('chartsGraphOne')) {
        this.myObserver.unobserve(document.getElementById('chartsGraphOne'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById('chartsGraphOne')) {
        this.myObserver.unobserve(document.getElementById('chartsGraphOne'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
