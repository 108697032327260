<template>
  <scroll-layout>
    <div class="content">
      <!-- 充电数据 -->
      <div class="charing-info">
        <div class="header">
          <div class="title">充电数据</div>
          <div class="btn">
            <span :class="dataType === 1 ? 'btn-active' : ''" @click="btnCharingClick(1)">
              今日
            </span>
            <span :class="dataType === 2 ? 'btn-active' : ''" @click="btnCharingClick(2)">
              昨日
            </span>
          </div>
        </div>
        <div class="charing-info-box">
          <div class="charing-info-box-item by-1">
            <div class="text">充电电量（度）</div>
            <div class="value">
              <YkcCountTo
                :decimals="4"
                :duration="2000"
                :endVal="Number(chargeData.chargedPower)"></YkcCountTo>
            </div>
            <div class="img"></div>
          </div>
          <div class="charing-info-box-item by-2">
            <div class="text">充电次数（单）</div>
            <div class="value">
              <YkcCountTo :duration="2000" :endVal="Number(chargeData.chargedCount)"></YkcCountTo>
            </div>
            <div class="img"></div>
          </div>
          <div class="charing-info-box-item by-3">
            <div class="text">充电总金额（元）</div>
            <div class="value">
              <YkcCountTo
                :decimals="2"
                :duration="2000"
                :endVal="Number(chargeData.totalAmount)"></YkcCountTo>
            </div>
            <div class="img"></div>
          </div>
          <div class="charing-info-box-item by-4">
            <div class="text">订单电费（元）</div>
            <div class="value">
              <YkcCountTo
                :decimals="2"
                :duration="2000"
                :endVal="Number(chargeData.chargedAmount)"></YkcCountTo>
            </div>
            <div class="img"></div>
          </div>
          <div class="charing-info-box-item by-5">
            <div class="text">订单服务费（元）</div>
            <div class="value">
              <YkcCountTo
                :decimals="2"
                :duration="2000"
                :endVal="Number(chargeData.parkedAmount)"></YkcCountTo>
            </div>
            <div class="img"></div>
          </div>
        </div>
      </div>
      <!-- 实时数据 -->
      <div class="real-status">
        <!-- 电桩监控 -->
        <div class="left">
          <div class="left-title font">
            <span>实时状态</span>
            <span class="icon" @click="jumpToPage()">更多&nbsp;></span>
          </div>
          <div class="data">
            <div class="data-status status-1" @click="jumpToPage('3')">
              <div class="data-status-img img1"><span>充电</span></div>
              <div class="data-status-value">
                <div class="data-status-value-num">
                  <YkcCountTo
                    :duration="2000"
                    :endVal="Number(pileData.chargingGunNum)"></YkcCountTo>
                </div>
                <div class="data-status-value-text">充电中</div>
              </div>
            </div>
            <div class="data-status status-2" @click="jumpToPage('2')">
              <div class="data-status-img img2"><span>空闲</span></div>
              <div class="data-status-value">
                <div class="data-status-value-num">
                  <YkcCountTo :duration="2000" :endVal="Number(pileData.freeGunNum)"></YkcCountTo>
                </div>
                <div class="data-status-value-text">空闲中</div>
              </div>
            </div>
            <div class="data-status status-3" @click="jumpToPage('0')">
              <div class="data-status-img img3"><span>离线</span></div>
              <div class="data-status-value">
                <div class="data-status-value-num">
                  <YkcCountTo
                    :duration="2000"
                    :endVal="Number(pileData.offLineGunNum)"></YkcCountTo>
                </div>
                <div class="data-status-value-text">离线中</div>
              </div>
            </div>
            <div class="data-status status-4" @click="jumpToPage('1')">
              <div class="data-status-img img4"><span>故障</span></div>
              <div class="data-status-value">
                <div class="data-status-value-num">
                  <YkcCountTo :duration="2000" :endVal="Number(pileData.faultGunNum)"></YkcCountTo>
                </div>
                <div class="data-status-value-text">故障中</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 常用功能 -->
        <div class="right">
          <div class="right-title font">常用功能</div>
          <div class="data">
            <div class="data-common-functions" @click="jumpToPage('real')">
              <div class="img real-order"></div>
              <p>实时订单</p>
            </div>
            <div class="data-common-functions" @click="jumpToPage('alarm')">
              <div class="img alarm-statistics"></div>
              <p>告警统计</p>
            </div>
            <div class="data-common-functions" @click="jumpToPage('station')">
              <div class="img station-manage"></div>
              <p>电站管理</p>
            </div>
            <div class="data-common-functions" @click="jumpToPage('price')">
              <div class="img charging-price"></div>
              <p>充电价格策略</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 图表 场站经营数据 -->
      <div class="station-operation">
        <div class="header">
          <div class="title">场站经营数据</div>
          <div class="btn">
            <span :class="type === 1 ? 'btn-active' : ''" @click="btnClick(1)">近7天</span>
            <span :class="type === 2 ? 'btn-active' : ''" @click="btnClick(2)">近30天</span>
          </div>
        </div>
        <div class="echarts">
          <div class="echarts-1 line">
            <ChartsGraphOne :data="operationTrendData" v-if="operationTrendData.length > 0" />
          </div>
          <div class="echarts-2 line">
            <ChartsGraphTwo :data="timeSegmentTrendData" v-if="timeSegmentTrendData.length > 0" />
          </div>
        </div>
      </div>
    </div>
  </scroll-layout>
</template>
<script>
  import ChartsGraphOne from '../ChartsGraphOne.vue';
  import ChartsGraphTwo from '../ChartsGraphTwo.vue';
  import YkcCountTo from '../../overview/operation/YkcCountTo.vue';
  import { workbench } from '@/service/apis';
  import { sessionGetItem } from '@/utils';

  export default {
    name: 'stagingList',
    components: { ChartsGraphOne, ChartsGraphTwo, YkcCountTo },
    data() {
      return {
        dateTime: '',
        dataType: 1,
        chargeData: {}, // 充电数据
        pileData: {}, // 电桩数据
        type: 1, // 1：近7天，2：近30天
        operationTrendData: [], // 场站经营 充电趋势数据
        timeSegmentTrendData: [], // 场站经营 时段分布数据
      };
    },
    created() {
      this.getToday();
      this.queryChargeDate();
      this.queryMonitorCount();
      this.operationTrend();
      this.timeSegmentTrend();
    },
    methods: {
      // 近7天/近30天
      btnClick(e) {
        this.type = e;
        if (this.type && [1, 2].includes(this.type)) {
          this.operationTrend();
          this.timeSegmentTrend();
        }
      },

      // 今日/昨日
      btnCharingClick(e) {
        this.dataType = e;
        if (this.dataType && this.dataType === 2) {
          this.getYestoday();
          this.queryChargeDate();
        }
        if (this.dataType && this.dataType === 1) {
          this.getToday();
          this.queryChargeDate();
        }
      },
      // 获取今日日期
      getToday() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.dateTime = `${year}-${month}-${day}`;
        console.log('-------今日', this.dateTime);
      },
      // 获取昨日日期
      getYestoday() {
        const today = new Date();
        today.setTime(today.getTime() - 24 * 60 * 60 * 1000);
        this.dateTime = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(today.getDate()).padStart(2, '0')}`;
        console.log('-------昨日', this.dateTime);
      },
      // 查询充电数据
      queryChargeDate() {
        workbench
          .chargeData({ dateTime: this.dateTime })
          .then(res => {
            console.log('chargeData', res);
            this.chargeData = res || {};
          })
          .catch(err => console.log(err));
      },
      // 查询电桩监控
      queryMonitorCount() {
        workbench
          .monitorCount({})
          .then(res => {
            console.log('pileData', res);
            this.pileData = res || {};
          })
          .catch(err => console.log(err));
      },
      // 场站经营 充电趋势图表
      operationTrend() {
        this.operationTrendData = [];
        workbench.operationTrend({ type: this.type }).then(res => {
          if (res && Array.isArray(res)) {
            this.operationTrendData = res.map(item => {
              return item;
            });
          }
        });
      },
      // 场站经营 时段分布图表
      timeSegmentTrend() {
        this.timeSegmentTrendData = [];
        workbench.timeSegmentTrend({ type: this.type }).then(res => {
          if (res && Array.isArray(res)) {
            this.timeSegmentTrendData = res.map(item => {
              return item;
            });
          }
        });
      },
      // 页面跳转
      jumpToPage(param) {
        // 跳电桩监控
        if (['0', '1', '2', '3'].includes(param)) {
          this.$router.push({
            path: '/staging/staging/pileMonitor',
            query: {
              gunStatus: Number(param),
            },
          });
        }
        if (!param) {
          this.$router.push({
            path: '/staging/staging/pileMonitor',
            query: {},
          });
        }
        // 实时订单
        if (param === 'real') {
          this.$router.push({
            path: '/orderManagement/realTimeOrder/List',
            query: {},
          });
        }
        // 告警统计
        if (param === 'alarm') {
          if (sessionGetItem('roleType') === '1') {
            this.$router.push({
              path: '/operationManagement/alarmInfo/alarmStatistics',
              query: {},
            });
          } else {
            this.$dialog({
              dialogType: 'feedback',
              showTitle: false,
              closeOnClickModal: false,
              desc: '暂无权限查看，请联系管理员配置权限',
              onConfirm: done => {
                done();
              },
            });
          }
        }
        // 电站管理
        if (param === 'station') {
          if (sessionGetItem('roleType') === '4') {
            this.$dialog({
              dialogType: 'feedback',
              showTitle: false,
              closeOnClickModal: false,
              desc: '暂无权限查看，请联系管理员配置权限',
              onConfirm: done => {
                done();
              },
            });
          } else {
            this.$router.push({
              path: '/plantAssets/powerStationManagement/directConnectionStation/List',
              query: {},
            });
          }
        }
        // 价格策略
        if (param === 'price') {
          if (sessionGetItem('roleType') === '4') {
            this.$dialog({
              dialogType: 'feedback',
              showTitle: false,
              closeOnClickModal: false,
              desc: '暂无权限查看，请联系管理员配置权限',
              onConfirm: done => {
                done();
              },
            });
          } else {
            this.$router.push({
              path: '/operationCenter/priceManage/chargingPriceStrategy',
              query: {},
            });
          }
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    width: 100%;
    .charing-info {
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      display: flex;
      flex-direction: column;
      height: 250px;
      margin-bottom: 12px;

      .header {
        display: flex;
        justify-content: space-between;
        height: 75px;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          line-height: 22px;
          margin: 16px 0 0 14px;
        }
        .btn {
          margin: 34px 34px 0 0;
          span {
            width: 40px;
            height: 26px;
            padding: 2.5px 5px;
            font-weight: 500;
            font-size: 14px;
            color: #4d8cfd;
            line-height: 20px;
            text-align: center;
            background: #edf3fe;
            border: 1px solid #4d8cfd;
            cursor: pointer;
          }
          &-active {
            background: #4d8cfd !important;
            color: #edf3fe !important;
          }
        }
      }

      &-box {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 43px;

        &-item {
          margin-right: 24px;
          width: calc((100% - 96px) / 5);
          border-radius: 20px;
          height: 140px;
          color: #ffffff;
          text-align: center;
          position: relative;
          cursor: pointer;

          .text {
            margin-top: 30px;
            margin-bottom: 11px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
          }
          .value {
            font-weight: 600;
            font-size: 41px;
            line-height: 57px;
          }
          .img {
            width: 80px;
            height: 80px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .by-1 {
          background: #15c350;
          .img {
            background: url('~@/assets/images/staging/electricity.png') no-repeat 100%;
          }
          &:hover {
            background: #0a9c3b;
          }
        }
        .by-2 {
          background: #ffb100;
          .img {
            background: url('~@/assets/images/staging/times.png') no-repeat 100%;
          }
          &:hover {
            background: #dda119;
          }
        }
        .by-3 {
          background: #02c1ff;
          .img {
            background: url('~@/assets/images/staging/amount.png') no-repeat 100%;
          }
          &:hover {
            background: #0ca2d3;
          }
        }
        .by-4 {
          background: #7c7eff;
          .img {
            background: url('~@/assets/images/staging/electric-charge.png') no-repeat 100%;
          }
          &:hover {
            background: #5254db;
          }
        }
        .by-5 {
          background: #0778f6;
          .img {
            background: url('~@/assets/images/staging/service-fee.png') no-repeat 100%;
          }
          &:hover {
            background: #1365c0;
          }
          margin-right: 0 !important;
        }
      }
    }

    .real-status {
      background: #f6f7f8;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;
      .font {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
        text-align: justify;
      }
      .left {
        width: 60%;
        margin-right: 11px;
        background: #ffffff;
        box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
        &-title {
          margin: 18px 0px 51px 17px;
          display: flex;
          justify-content: space-between;
          .icon {
            margin-right: 60px;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .data {
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: space-around;

          &-status {
            height: 75px;
            text-align: center;
            display: flex;
            cursor: pointer;
            &-img {
              width: 68px;
              height: 68px;
              position: relative;
              span {
                color: #ffffff;
                text-align: center;
              }
              transition: transform 0.3s ease;
              &:hover {
                transform: scale(1.08);
              }
            }
            .img1 {
              background: url('~@/assets/images/staging/charging.png') no-repeat 100%;
              span {
                line-height: 21px;
                font-size: 16px;
                position: relative;
                top: 31%;
              }
            }
            .img2 {
              background: url('~@/assets/images/staging/Idle.png') no-repeat 100%;
              span {
                line-height: 21px;
                font-size: 16px;
                position: relative;
                top: 31%;
              }
            }
            .img3 {
              background: url('~@/assets/images/staging/offline.png') no-repeat 100%;
              span {
                font-size: 10px;
                line-height: 13px;
                position: relative;
                top: 48%;
                left: 5%;
              }
            }
            .img4 {
              background: url('~@/assets/images/staging/fault.png') no-repeat 100%;
              span {
                font-size: 10px;
                line-height: 13px;
                position: relative;
                top: 50%;
                left: 6%;
              }
            }
            &-value {
              margin-left: 20px;

              &-num {
                font-weight: 600;
                font-size: 24px;
                color: #000000;
                line-height: 48px;
                text-align: center;
              }
              &-text {
                font-weight: 500;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 20px;
                text-align: center;
              }
            }
          }
        }
      }
      .right {
        width: calc(40% - 11px);
        background: #ffffff;
        box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
        &-title {
          margin: 20px 0px 33px 11px;
        }
        .data {
          height: 148px;
          display: flex;
          justify-content: space-around;
          &-common-functions {
            cursor: pointer;
            .img {
              width: 60px;
              height: 60px;
              transition: transform 0.3s ease;
              &:hover {
                transform: scale(1.1);
              }
            }
            .real-order {
              background: url('~@/assets/images/staging/real-order.png') no-repeat 100%;
            }
            .alarm-statistics {
              background: url('~@/assets/images/staging/alarm-statistics.png') no-repeat 100%;
            }
            .station-manage {
              background: url('~@/assets/images/staging/station-manage.png') no-repeat 100%;
            }
            .charging-price {
              margin-left: 10px;
              background: url('~@/assets/images/staging/charging-price.png') no-repeat 100%;
            }
            p {
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
    }

    .station-operation {
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);

      .header {
        display: flex;
        justify-content: space-between;
        // border: 1px solid #ebebf0;
        padding: 18px 45px 0 8px;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          line-height: 22px;
          text-align: left;
        }
        .btn {
          span {
            width: 54px;
            height: 26px;
            padding: 2.5px 5px;
            background: #edf3fe;
            border: 1px solid #4d8cfd;
            font-weight: 500;
            font-size: 14px;
            color: #4d8cfd;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
          }
          &-active {
            background: #4d8cfd !important;
            color: #edf3fe !important;
          }
        }
      }
      .echarts {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .line {
          border: 1px solid #ebebf0;
          height: 338px;
          margin-top: 0 !important;
        }
        &-1 {
          margin-right: 20px;
        }
      }
    }
  }
</style>
